/* You can add global styles to this file, and also import other style files */
@import '~ngx-toastr/toastr';
@import "src/assets/styles/global-admin-styles";

html,
body {
    height: 100%;
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
