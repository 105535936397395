@import "Variables";

.option-title {
    color: $pq-main;
    font-weight: 100;
    padding: 10px 0 5px 0;
}

.button-standart-positive {
    padding: 5px;
    color: $pq-main;
    border: 1px solid $pq-main;
    margin: 5px;

    &:hover {
        background-color: $pq-main;
        color: white;
        cursor: pointer;
    }
    @media only screen and (max-width: $screen-medium) {
        width: 100%;
        padding: 10px;
        margin: 5px 5px 5px 0;
    }
}

.button-standart-negative {
    color: darkred;
    border: 1px solid darkred;
    padding: 5px;
    margin: 5px;

    &:hover {
        background-color: darkred;
        color: white;
        cursor: pointer;
    }

    &:disabled {
        color: dimgray;
        border: 1px solid dimgray;
        cursor: not-allowed;

        &:hover {
            background-color: white;
            color: dimgray;
        }
    }
    @media only screen and (max-width: $screen-medium) {
        width: 100%;
        padding: 10px;
        margin: 5px 5px 5px 0;
    }
}
